import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// const $ = window.$ = window.jQuery = require('jquery');
import './../node_modules/bootstrap/dist/js/bootstrap';

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')

