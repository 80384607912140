<template>
    <div class="global-container">
        <router-view/>
    </div>
    <div class="page-footer">
        <p class="copyright">©2024. Національний університет "Одеська політехніка"</p>
    </div>
</template>

<style lang="less">
    @import './assets/less/index.less';
</style>

<script>
export default {
}
</script>
