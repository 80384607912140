import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/for-students/humanities-disciplines-for-students',
        name: 'humanities-disciplines-for-students',
        component: () => import('../views/for-students/HumanitiesDisciplinesForStudentsView.vue')
    },
    {
        path: '/for-departments/professional-disciplines-for-departments',
        name: 'professional-disciplines-for-departments',
        component: () => import('../views/for-departments/ProfessionalDisciplinesForDepartments.vue')
    },
    {
        path: '/reports/allstudentsreportview',
        name: 'allstudentsreportview',
        component: () => import('../views/reports/AllStudentsReportView.vue')
    },
    {
        path: '/reports/yearstudentsreportview',
        name: 'yearstudentsreportview',
        component: () => import('../views/reports/YearStudentsReportView.vue')
    },
    {
        path: '/reports/alldepartmentsreportview',
        name: 'alldepartmentsreportview',
        component: () => import('../views/reports/AllDepartmentsReportView.vue')
    },
    {
        path: '/reports/departmentreportview',
        name: 'departmentreportview',
        component: () => import('../views/reports/DepartmentReportView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
